<template>
  <div class="warehouseInfo">
    <div class="pvhFactory">
        <div class="">
            <div class="layTop d-flex">
                <div class="Title" style="margin-bottom: 10px;">{{ $t('Data Log') }}</div>

            </div>
            <el-row :gutter="20" class="topFromSearch">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="height: auto;">
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                        <span id="typeSpan" :style="">{{$t('Data Type')}}:</span>
                        <el-select v-model="typeVal" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple collapse-tags placeholder="" style="width:100%;">
                            <el-option v-for="item in GetCurrTypeData"
                                       :key="item.code"
                                       :label="item.name"
                                       :value="item.code">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                        <span :style="beginTimeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('BEINGTIME')}}:</span>
                        <el-date-picker class="dataicon" v-model="beginTimeVal"
                                        type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                        </el-date-picker>
                    </el-col>
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                        <span :style="endTimeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('ENDTIME')}}:</span>
                        <el-date-picker class="dataicon" v-model="endTimeVal"
                                        type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                        </el-date-picker>
                    </el-col>
                </el-col>

            </el-row>
            <el-row :gutter="20" class="topFromSearch">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: right; align-self: flex-end;">
                    <el-button :title="$t('Search')" icon="iconfont icon-textile-sousuo" size="mini" type="primary" @click="search">  {{$t('Search')}}</el-button>
                </el-col>
            </el-row>
            <div style="padding-top: 10px; text-align: right; align-self: flex-end;">
            </div>
            <div style="margin-top: 10px;">
                <avue-crud class="pvhTable"
                           v-loading="loading"
                           v-model="obj"
                           :option="tableOpt"
                           :data="tableData"
                           :row-style="rowStyle"
                           :page.sync="page"
                           @current-change="currentChange">

                    <template slot="menuRight" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">

                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 10px;">
                                <el-button type="primary" size="small" icon="el-icon-plus" style="margin-left:10px;">
                                    {{ $t('ADD') }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button type="text" size="small" @click="viewBtn(row)">{{ $t('View') }}</el-button>
                        <el-button type="text" size="small" v-if="row.dataType =='01' || row.dataType =='02'" @click="downloadBeginBtn(row)">{{ $t('DownloadRequest') }}</el-button>
                        <el-button type="text" size="small" v-if="row.dataType =='01' || row.dataType =='02'" @click="downloadEndBtn(row)">{{ $t('DownloadResult') }}</el-button>
                    </template>
                </avue-crud>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
import {
        GetListDataType,
        GetListDataLogInfosByType,
        GetDataLogInfos,
        GetDataLogInfo,
        PostDataLog
    } from '@/api/datalog'
    import { datalogApiBase } from '@/config/env'
export default {
  name: "datalog",
  data() {
    return {
        loading: false,
        viewdetail: false,
        GetCurrTypeData:[],
        dialogVisible: false,
        codeVal: '',
        statusVal: '',
        warehouseTypeVal: '',
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
      },
      tableData: [],
        obj: {},
    }
  },
  created() {
    this.list();
      GetListDataType().then((response) => {
          this.GetCurrTypeData = response.data;
      });
  },
  methods: {
    search() {
        this.list();
    },
    list() {
        this.loading = true;
        GetDataLogInfos(this.typeVal, this.beginTimeVal, this.endTimeVal, this.page.currentPage, this.page.pageSize).then((response) => {
        this.loading = false;
        this.tableData = response.data.dataLogs;
        this.page.total = response.data.totalCount;
        this.page.pageSize = response.data.pageSize;
      })
      },
      viewBtn(row) {
          if (row.dataType == "01" || row.dataType == "02") {
              this.$router.push({ path: '/logs/dataImport', query: { ID: row.id } })
          }
          else if (row.dataType == "03") {
              this.$router.push({ path: '/logs/dataInterface', query: { ID: row.id } })
          }
      },
      downloadBeginBtn(row) {
          this.downUrl = datalogApiBase + "Log/GetExcelFilePathStream?filepath=" + row.dataContent;
          this.downloadExcel(this.downUrl, "DataContent");
      },
      downloadEndBtn(row) {
          this.downUrl = datalogApiBase + "Log/GetExcelFilePathStream?filepath=" + row.resultDetail;
          this.downloadExcel(this.downUrl, "ResultDetail");
      },
      downloadExcel(url, fileName) {
          fetch(url, {
              //headers: {
              //    Authorization: 'Bearer ' + getToken(),
              //    'corpId': getGrop()
              //}
          }).then(function (response) {
              if (response.ok) {
                  return response.arrayBuffer()
              }
              throw new Error('Network response was not ok.')
          }).then(function (arraybuffer) {
              let blob = new Blob([arraybuffer], {
                  type: `application/vnd.ms-excel`
              })
              let objectURL = URL.createObjectURL(blob)
              let downEle = document.createElement('a')
              let fname = fileName // �����ļ�������
              // let fname = `download` // �����ļ�������
              downEle.href = objectURL
              downEle.setAttribute('download', fname + '.xlsx')
              document.body.appendChild(downEle)
              downEle.click()
          }).catch(function (error) {
              console.log('There has been a problem with your fetch operation: ', error.message)
          })
      },
    rowStyle({rowIndex}) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
      },
      focusDiv(obj) {//�������ȡ����
          if (obj.currentTarget === undefined) {
              obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
          } else {
              if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                  obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
              } else {
                  obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
              }
          }
      },//������ʧȥ���� 
      blurDiv(obj) {
          if (obj.currentTarget === undefined) {
              if (obj.value === "") {
                  obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266;";
              }
          } else if (obj.currentTarget === null) {
              if (this.fromVal.ids.length === 0) {
                  document.querySelector("#statusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266;";
                  document.querySelector("#warehouseTypeSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266;";
              }
          } else {
              if (obj.target.value == "") {
                  obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
              }
          }
      },
    currentChange(val) {
        this.page.currentPage = val;
        this.list();
    }
  },
  computed: {
    tableOpt() {
      return {
        page: true,
        addBtn: true,
        refreshBtn: false,
        emptyText: this.$t('No Data'),
        menu: true,
        menuTitle: this.$t('OPERATION'),
        menuWidth: 300,
        editBtn: false,
        editBtnText: this.$t('Edit'),
        editTitle: this.$t('Edit'),
        addTitle: this.$t('ADD'),
        delBtn: false,
        align: 'center',
        header: false,
        simplePage: false,
        labelWidth: "33%",
        expand: false,
        rowKey: 'Id',
        expandRowKeys: [],
        ListByCorpData: [],
        column: [
          {
                label: this.$t('Data Type'),
                prop: 'dataType',
          },
          {
              label: this.$t('DataContent'),
              prop: 'dataContent',
          },
          {
              label: this.$t('ResultContent'),
              prop: 'resultContent',
          },
          {
              label: this.$t('ResultDetail'),
              prop: 'resultDetail',
            },
            {
                label: this.$t('DATE'),
                prop: 'createTime',
                type: "date",
                format: 'yyyy-MM-dd',
                valueFormat: 'yyyy-MM-dd',
            }
        ]
      }
    },
  }
}
</script>

<style scoped lang="scss">
.datalog {
    padding: 15px;

    .pvhFactory {
        padding: 15px;
        border-radius: 8px;
        background: #FFFFFF;
    }
}

.layTop {
  justify-content: space-between;
}

.d-flex {
  display: flex;
}
</style>
